@import "src/scss/module";

.TileGroups {

  &__group {

    h3 {
      background-color: var(#{--bs-tertiary});
      color: var(#{--bs-white});
      padding: .75rem;
      border-radius: 5px;
      overflow: hidden;
      text-align: center;
    }

    & > footer {

      a {
        display: flex;
        align-items: center;
        gap: .5rem;
        color: var(#{--bs-text-color});
        text-decoration: none;
        font-weight: $font-weight-bold;

        &::after {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid;
          border-top-color: var(#{--bs-text-color});
        }
      }
    }
  }

  &__card {
    position: relative;
    color: var(#{--bs-white});
    border-radius: 5px;
    overflow: hidden;
    background-color: $black;
    display: block;

    & > img {
      opacity: .8;
      transition: .3s;
    }

    &:hover > img {
      scale: 1.1;
    }

    i {
      position: absolute;
      top: 0;
      right: 0;
      background-color: var(#{--bs-quaternary});
      color: var(#{--bs-white});
      font-style: normal;
      border-bottom-left-radius: 5px;
      font-size: 12px;
      display: flex;
      z-index: 1;

      img {
        width: 12px;
        height: 12px;
        object-fit: cover;
      }
    }

    img {
      width: 100%;
    }

    footer {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1rem 1.25rem;

      a {
        color: var(#{--bs-text-color});
        text-decoration: none;
        font-weight: $font-weight-bold;
      }
    }
  }
}